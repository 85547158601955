import { createRequestConfig } from '@src/service/createRequestConfig';

export const registerUserUsingPost = createRequestConfig<
  {
    requestBody: ManualUserRegisterRequest;
  },
  ManualCustomerOnboardResponse
>('registerUserUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/kyc/manual-onboard/register-user`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const searchCityByPrefixUsingPost = createRequestConfig<
  {
    requestBody: CountryAndCitySearchPrefixRequest;
  },
  CityListResponse
>('searchCityByPrefixUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/kyc/manual-onboard/search/city`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const searchCountryByPrefixUsingPost = createRequestConfig<
  {
    requestBody: CountryAndCitySearchPrefixRequest;
  },
  CountryListResponse
>('searchCountryByPrefixUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/kyc/manual-onboard/search/country`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const searchUserUsingGet = createRequestConfig<
  {
    countryCode: string;
    phoneNumber: string;
  },
  ManualCustomerOnboardResponse
>('searchUserUsingGet', ({ countryCode, phoneNumber }) => ({
  url: `/admin-portal/v1/kyc/manual-onboard/search-user`,
  method: 'GET',
  params: {
    countryCode,
    phoneNumber,
  },
}));

export const uploadPhotoUsingPost = createRequestConfig<
  {
    file: Blob;
  },
  ManualKycPhotoUploadResponse
>('uploadPhotoUsingPost', ({ file }) => ({
  url: `/admin-portal/v1/kyc/manual-onboard/photo`,
  method: 'POST',
  params: {
    file,
  },
}));

export const verifyKycData1UsingPost = createRequestConfig<
  {
    requestBody: ManualKycCitizenOnboardingRequest;
  },
  ManualOnboardResponse
>('verifyKycData1UsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/kyc/manual-onboard/verify-citizen`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const verifyKycDataUsingPost = createRequestConfig<{
  requestBody: ManualKycOnboardingRequest;
}>('verifyKycDataUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/kyc/manual-onboard/verify`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export interface City {
  countryCode?: string;
  countryIsoCode?: string;
  id?: number;
  name?: string;
}

export interface CityListResponse {
  cityList?: City[];
}

export interface Country {
  code?: string;
  id?: number;
  isBlacklisted?: boolean;
  isoCode?: string;
  name?: string;
}

export interface CountryAndCitySearchPrefixRequest {
  numberOfEntries?: number;
  searchPrefix?: string;
}

export interface CountryListResponse {
  countryList?: Country[];
}

export interface ManualCustomerOnboardResponse {
  nanotag?: string;
  userId?: number;
}

export interface ManualKycCitizenOnboardingRequest {
  accountFundingSource: string;
  address: string;
  annualIncomeTier: string;
  birthDate: string;
  birthPlace: string;
  citizenship: string;
  city: string;
  customerId: number;
  district: string;
  domicileCity?: string;
  domicileDistrict?: string;
  domicilePostalCode?: string;
  domicileProvince?: string;
  domicileStreetAddress?: string;
  domicileSubDistrict?: string;
  email?: string;
  employerAddress?: string;
  employerName?: string;
  employment?: string;
  employmentStatus?: string;
  expiration: string;
  investmentObjective: string;
  jobTitle?: string;
  ktpPhotoUrl: string;
  kycChannel?: keyof typeof ManualKycCitizenOnboardingRequestKycChannel;
  liquidAssetTier: string;
  marriageStatus: string;
  name: string;
  nik: string;
  npwpNumber: string;
  postalCode: string;
  privacyPolicyUrl?: string;
  province: string;
  religion: string;
  selfiePhotoUrl: string;
  subDistrict: string;
  termsAndConditions: TermAndCondition[];
  tncUrl?: string;
}

export enum ManualKycCitizenOnboardingRequestKycChannel {
  'IDDB' = 'IDDB',
  'NANOVEST' = 'NANOVEST',
}

export interface ManualKycOnboardingRequest {
  accountFundingSource: string;
  annualIncomeTier: string;
  birthDate: string;
  birthPlace: string;
  citizenship: string;
  city: string;
  country: string;
  email?: string;
  employerAddress?: string;
  employerName?: string;
  employment?: string;
  employmentStatus?: string;
  investmentObjective: string;
  isForeignUser?: boolean;
  jobTitle?: string;
  kitapOrKitasPhotoUrl?: string;
  kitasAddress: string;
  kitasExpiryDate: string;
  kycChannel?: keyof typeof ManualKycOnboardingRequestKycChannel;
  liquidAssetTier: string;
  marriageStatus: string;
  name: string;
  npwpNumber: string;
  passport?: string;
  passportExpirationDate: string;
  passportPhotoUrl: string;
  postalCode: string;
  privacyPolicyUrl?: string;
  religion: string;
  selfiePhotoUrl: string;
  streetAddress: string;
  tncUrl?: string;
  userId: number;
}

export enum ManualKycOnboardingRequestKycChannel {
  'IDDB' = 'IDDB',
  'NANOVEST' = 'NANOVEST',
}

export interface ManualKycPhotoUploadResponse {
  signedImageUrl?: string;
  unsignedImageUrl?: string;
}

export interface ManualOnboardResponse {
  message?: string;
}

export interface ManualUserRegisterRequest {
  countryCode: string;
  nanotag: string;
  phoneNumber: string;
}

export interface TermAndCondition {
  check?: boolean;
  id?: string;
}
