import { createRequestConfig } from '../../createRequestConfig';

export const addPendingTagUsingPost = createRequestConfig<{
  id: number;
}>('addPendingTagUsingPost', ({ id }) => ({
  url: `/admin-portal/v1/kyc-users/${id}/tag`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
}));

export const removePendingTagUsingDelete = createRequestConfig<{
  id: number;
}>('removePendingTagUsingDelete', ({ id }) => ({
  url: `/admin-portal/v1/kyc-users/${id}/tag`,
  method: 'DELETE',
}));

export const retrieveKycUserByIdUsingGet = createRequestConfig<
  {
    id: number;
  },
  KycSingleUserResponse
>('retrieveKycUserByIdUsingGet', ({ id }) => ({
  url: `/admin-portal/v1/kyc-users/${id}`,
  method: 'GET',
}));

export const retrieveKycUsersUsingPost = createRequestConfig<
  {
    requestBody: KycUsersRequest;
  },
  KycUsersPageResponse
>('retrieveKycUsersUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/kyc-users`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const sendVerificationEmailUsingPost = createRequestConfig<{
  requestBody: SendVerificationEmailRequest;
}>('sendVerificationEmailUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/kyc-users/send-verification-email`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export interface BlackList {
  status?: keyof typeof BlackListStatus;
  types?: string[];
}

export enum BlackListStatus {
  'APPROVED' = 'APPROVED',
  'BLACKLIST_HIT' = 'BLACKLIST_HIT',
  'HIT' = 'HIT',
  'NOT_HIT' = 'NOT_HIT',
}

export interface CustomerKtp {
  address?: string;
  birthDate?: Timestamp;
  birthPlace?: string;
  bloodType?: string;
  citizenship?: string;
  city?: string;
  createAt?: Timestamp;
  district?: string;
  employment?: string;
  expiration?: string;
  gender?: string;
  id?: number;
  imgUrl?: string;
  marriageStatus?: string;
  name?: string;
  nameLowerCase?: string;
  nik?: string;
  province?: string;
  religion?: string;
  rtrw?: string;
  subDistrict?: string;
  updateAt?: Timestamp;
  userId?: string;
}

export interface DuplicatedKyc {
  approvedAt?: Timestamp;
  citizenship?: string;
  customerId?: number;
  imgUrl?: string;
  kycStatus?: keyof typeof DuplicatedKycKycStatus;
  name?: string;
  nik?: string;
  passportNumber?: string;
  phoneNumber?: string;
}

export enum DuplicatedKycKycStatus {
  'APPROVED' = 'APPROVED',
  'IN_COMPLETE' = 'IN_COMPLETE',
  'REJECTED' = 'REJECTED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export interface EKyc {
  basicData?: keyof typeof EKycBasicData;
  dedup?: keyof typeof EKycDedup;
  dukcapil?: keyof typeof EKycDukcapil;
  dukcapilFace?: keyof typeof EKycDukcapilFace;
  email?: keyof typeof EKycEmail;
  forgeryCheck?: keyof typeof EKycForgeryCheck;
  livenessCheck?: keyof typeof EKycLivenessCheck;
  numDukcapilPassed?: number;
  selfieKtp?: keyof typeof EKycSelfieKtp;
  selfieVsPassport?: keyof typeof EKycSelfieVsPassport;
}

export enum EKycBasicData {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum EKycDedup {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum EKycDukcapil {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum EKycDukcapilFace {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum EKycEmail {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum EKycForgeryCheck {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum EKycLivenessCheck {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum EKycSelfieKtp {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum EKycSelfieVsPassport {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export interface FacialMatchData {
  selfieImgUrl?: string;
  selfieVsPassportMatchScore?: number;
}

export interface KycPortalDisplayLivenessResult {
  birthDateMatch?: boolean;
  dukcapilVendor?: keyof typeof KycPortalDisplayLivenessResultDukcapilVendor;
  livenessMatch?: boolean;
  livenessScore?: number;
  nameMatch?: boolean;
  nikMatch?: boolean;
  secondaryFaceMatchScore?: number;
  selfieImgUrl?: string;
  selfieMatch?: boolean;
  selfiePhotoScore?: number;
}

export enum KycPortalDisplayLivenessResultDukcapilVendor {
  'ADVANCE_AI' = 'ADVANCE_AI',
  'ASLIRI' = 'ASLIRI',
  'COMPLY_ADVANTAGE' = 'COMPLY_ADVANTAGE',
  'VERIHUBS' = 'VERIHUBS',
}

export interface KycSingleUserResponse {
  kycUser?: KycUser;
}

export interface KycUser {
  blackList?: BlackList;
  customerId?: number;
  duplicatedKyc?: DuplicatedKyc;
  ekyc?: EKyc;
  email?: string;
  facialMatchData?: FacialMatchData;
  forgeryFailReason?: string;
  ktp?: CustomerKtp;
  kycFailReason?: keyof typeof KycUserKycFailReason;
  kycMfStatus?: keyof typeof KycUserKycMfStatus;
  kycStatus?: keyof typeof KycUserKycStatus;
  kycStep?: keyof typeof KycUserKycStep;
  kycUpdatedAt?: number;
  livenessResult?: KycPortalDisplayLivenessResult;
  manualEKyc?: boolean;
  manualEKycChecklist?: ManualEKycChecklist;
  manualKycOnboardingInfo?: ManualKycOnboardingInfo;
  name?: string;
  occupation?: Occupation;
  pendingApproval?: boolean;
  phone?: string;
  submissionDate?: number;
  tagUpdatedBy?: string;
  tagged?: boolean;
  terms?: Terms;
  unverifiedEmail?: string;
}

export enum KycUserKycFailReason {
  'BLACKLIST_CHECK_FAIL' = 'BLACKLIST_CHECK_FAIL',
  'DC_BELOW_THRESHOLD' = 'DC_BELOW_THRESHOLD',
  'DUKCAPIL_MISMATCH' = 'DUKCAPIL_MISMATCH',
  'DUPLICATE' = 'DUPLICATE',
  'ID_FORGERY' = 'ID_FORGERY',
  'KTP_NOT_FOUND' = 'KTP_NOT_FOUND',
  'LIVENESS_FAIL' = 'LIVENESS_FAIL',
  'MAX_FAIL_ATTEMPT' = 'MAX_FAIL_ATTEMPT',
  'PASSIVE_LIVENESS_FAIL' = 'PASSIVE_LIVENESS_FAIL',
  'SELFIE_VS_KTP_BELOW_THRESHOLD' = 'SELFIE_VS_KTP_BELOW_THRESHOLD',
  'UNKNOWN' = 'UNKNOWN',
}

export enum KycUserKycMfStatus {
  'FAIL' = 'FAIL',
  'FAIL_KSEI' = 'FAIL_KSEI',
  'ON_PROGRESS' = 'ON_PROGRESS',
  'PENDING_ADMIN' = 'PENDING_ADMIN',
  'PENDING_KSEI' = 'PENDING_KSEI',
  'REVALIDATE_NEEDED' = 'REVALIDATE_NEEDED',
  'SUCCESS' = 'SUCCESS',
}

export enum KycUserKycStatus {
  'APPROVED' = 'APPROVED',
  'IN_COMPLETE' = 'IN_COMPLETE',
  'REJECTED' = 'REJECTED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum KycUserKycStep {
  'BASIC_FINANCIAL_FILLED' = 'BASIC_FINANCIAL_FILLED',
  'EMAIL_VERIFIED' = 'EMAIL_VERIFIED',
  'EMPLOYMENT_FILLED' = 'EMPLOYMENT_FILLED',
  'ID_CARD_UPLOADED' = 'ID_CARD_UPLOADED',
  'KTP_INFO_CONFIRMED' = 'KTP_INFO_CONFIRMED',
  'LIVENESS_CHECKED' = 'LIVENESS_CHECKED',
  'START' = 'START',
  'TERMS_GROUND' = 'TERMS_GROUND',
}

export interface KycUsersPageResponse {
  kycUsers?: KycUser[];
  totalNumber?: number;
  totalPage?: number;
}

export interface KycUsersRequest {
  cipBlackListStatus?: keyof typeof KycUsersRequestCipBlackListStatus;
  currentPage: number;
  customerId?: string;
  emailAddress?: string;
  isForeignUser?: boolean;
  kycMfStatus?: keyof typeof KycUsersRequestKycMfStatus;
  kycStatus?: keyof typeof KycUsersRequestKycStatus;
  name?: string;
  nanoTag?: string;
  pageSize: number;
  pendingApprovalStatus?: keyof typeof KycUsersRequestPendingApprovalStatus;
  phone?: string;
  sortBySubmissionDate?: keyof typeof KycUsersRequestSortBySubmissionDate;
  submissionDateFrom?: string;
  submissionDateTo?: string;
}

export enum KycUsersRequestCipBlackListStatus {
  'APPROVED' = 'APPROVED',
  'BLACKLIST_HIT' = 'BLACKLIST_HIT',
  'HIT' = 'HIT',
  'NOT_HIT' = 'NOT_HIT',
}

export enum KycUsersRequestKycMfStatus {
  'FAIL' = 'FAIL',
  'FAIL_KSEI' = 'FAIL_KSEI',
  'ON_PROGRESS' = 'ON_PROGRESS',
  'PENDING_ADMIN' = 'PENDING_ADMIN',
  'PENDING_KSEI' = 'PENDING_KSEI',
  'REVALIDATE_NEEDED' = 'REVALIDATE_NEEDED',
  'SUCCESS' = 'SUCCESS',
}

export enum KycUsersRequestKycStatus {
  'APPROVED' = 'APPROVED',
  'IN_COMPLETE' = 'IN_COMPLETE',
  'REJECTED' = 'REJECTED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum KycUsersRequestPendingApprovalStatus {
  'ALL' = 'ALL',
  'NONE' = 'NONE',
  'WAITING_FOR_CHECKER' = 'WAITING_FOR_CHECKER',
}

export enum KycUsersRequestSortBySubmissionDate {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

export interface ManualEKycChecklist {
  birthDateMatch?: boolean;
  birthPlaceMatch?: boolean;
  nameMatch?: boolean;
  nikMatch?: boolean;
  selfiePhotoMatch?: boolean;
}

export interface ManualKycOnboardingInfo {
  accountFundingSource?: string;
  annualIncomeTier?: string;
  birthDate?: string;
  birthPlace?: string;
  citizenship?: string;
  city?: string;
  country?: string;
  createdAt?: Timestamp;
  email?: string;
  employerAddress?: string;
  employerName?: string;
  employment?: string;
  employmentStatus?: string;
  id?: number;
  isForeignUser?: boolean;
  jobTitle?: string;
  kitapOrKitasPhotoUrl?: string;
  liquidAssetTier?: string;
  marriageStatus?: string;
  name?: string;
  nameLowerCase?: string;
  passport?: string;
  passportExpirationDate?: string;
  passportPhotoUrl?: string;
  postalCode?: string;
  record?: string;
  religion?: string;
  selfiePhotoUrl?: string;
  streetAddress?: string;
  userId?: number;
}

export interface Occupation {
  companyAddress?: string;
  companyName?: string;
  title?: string;
}

export interface SendVerificationEmailRequest {
  userId?: number;
}

export interface Terms {
  affiliation?: keyof typeof TermsAffiliation;
  digitalSignature?: keyof typeof TermsDigitalSignature;
  employment?: keyof typeof TermsEmployment;
  pepPo?: keyof typeof TermsPepPo;
  shareHolder?: keyof typeof TermsShareHolder;
  solicitation?: keyof typeof TermsSolicitation;
  usCitizen?: keyof typeof TermsUsCitizen;
}

export enum TermsAffiliation {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum TermsDigitalSignature {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum TermsEmployment {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum TermsPepPo {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum TermsShareHolder {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum TermsSolicitation {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export enum TermsUsCitizen {
  'NOT_PASSED' = 'NOT_PASSED',
  'PASSED' = 'PASSED',
  'TO_BE_REVIEWED' = 'TO_BE_REVIEWED',
}

export interface Timestamp {
  nanos?: number;
  seconds?: number;
}
