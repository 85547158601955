import { useCallback } from 'react';

import {
  ROLE_TO_ROUTE_MAP,
  PAGE_PATH_TO_PERMISSION_MAPPING,
  PAGE_PATH_TO_ROLE_MAPPING,
  DEFAULT_PERMISSION_TO_ROUTE_MAP,
  NON_AUTHENTICATED_PATHS,
} from '@src/constants/common';
import { RoleResponseDto } from '@src/service/apis/generatedv3/role-controller';
import useUserStore from '@src/stores/user';
import {
  CheckForPathAccessFn,
  GetNextRouteFn,
  GetUserPermissionsFn,
  GetValidStaticRoleFromUserRolesFn,
  HasAccessByPermissionKeysFn,
  HasAccessByRoleFn,
  HasPageAccessFn,
  PermissionKey,
  UseCheckPermission,
} from '@src/types/hooks/useCheckPermission';
import { isEmpty } from '@src/utils/common';
import { SUPER_ADMIN_ROLE } from '@src/utils/constants';
import { isIntersectionNonEmpty } from '@src/utils/utils';

export const useCheckPermission: UseCheckPermission = () => {
  // NB: Global state values from user-store.
  const {
    setUserRoleList,
    setAccessibleFeatures,
    userRoleList,
    userAcls,
    setUserAcls,
  } = useUserStore();

  /**
   * Function to compute userRoleList, userAcls and featuresAccessible from userInfo
   * @param userRoles: details about user-roles from userInfo
   * @returns : Object - { userRoleList, featuresAccessible }
   * userRoleList: list of roleNames based on userRoles
   * featuresAccessible: list of all features accessible to user
   * userAcls: list of all user permissions
   */
  const getUserPermissions: GetUserPermissionsFn = (
    userRoles: RoleResponseDto[],
  ) => {
    const userRoleList = userRoles?.map(roleItem => roleItem.name);

    const featureList = userRoles?.reduce(
      (accumulator, roleItem: RoleResponseDto) =>
        isEmpty(roleItem.permissions)
          ? accumulator
          : [...accumulator, ...Object.keys(roleItem.permissions)],
      [],
    );
    const featuresKeys = Array.from(new Set(featureList));

    const userAclInfo = userRoles?.reduce(
      (accumulator, roleItem: RoleResponseDto) => {
        const { permissions } = roleItem;
        if (isEmpty(permissions)) return accumulator;

        const newAcls = [];
        Object.keys(permissions).forEach(featureNameKey => {
          newAcls.push(...permissions[featureNameKey]);
        });
        return [...accumulator, ...newAcls];
      },
      [],
    );
    const userAcls = Array.from(new Set(userAclInfo.map(item => item.name)));

    setUserRoleList(userRoleList);
    setAccessibleFeatures(featuresKeys);
    setUserAcls(userAcls);

    return { userRoleList, userAcls };
  };

  /**
   * function to check if user has access rights to given role/roles, to support static role-management
   * @param requiredRole - role/set of roles to check permission for
   * @param allowSuperAdmin - used to determine if SUPER_ADMIN should be allowed access by default
   * @returns - boolean
   */
  const hasAccessByRole: HasAccessByRoleFn = (
    requiredRole: string | string[],
    allowSuperAdmin = true,
  ) => {
    if (allowSuperAdmin && userRoleList?.includes(SUPER_ADMIN_ROLE))
      return true;

    if (
      !(typeof requiredRole === 'string' || Array.isArray(requiredRole)) ||
      isEmpty(requiredRole) ||
      isEmpty(userRoleList)
    )
      return false;

    if (typeof requiredRole === 'string') {
      return userRoleList.includes(requiredRole);
    }

    return isIntersectionNonEmpty(userRoleList, requiredRole);
  };

  /**
   * Function to get valid static-role from list of all static roles
   * @param allStaticRoles - List of all static roles
   * @param userRoles - List of roles to which user has access-permission
   * @returns - string
   */
  const getValidStaticRoleFromUserRoles: GetValidStaticRoleFromUserRolesFn = (
    allStaticRoles: string[],
    userRoles: string[],
  ) => userRoles?.find(item => !!allStaticRoles?.includes(item));

  /**
   * Function to check if user can be given access to a page/functionality/button based on the set of permission-keys passed
   * @param permissionKeys - list of permission-keys that needs to be checked for
   * * @returns - boolean
   */
  const hasAccessByPermissionKeys: HasAccessByPermissionKeysFn = (
    permissionKeys: PermissionKey[],
  ) => {
    if (isEmpty(userAcls) || isEmpty(permissionKeys)) return false;

    return isIntersectionNonEmpty(userAcls, permissionKeys);
  };

  /**
   * Function to check if a page/menu is accessible, based on user roles/permissions
   * @param allowedRoles - list of roles allowed to access given menu-item
   * @param permissionKeys - list of permissions under a given menu-item
   * * @returns - boolean
   */
  const hasPageAccess: HasPageAccessFn = (
    allowedRoles: string[],
    permissionKeys?: PermissionKey[],
  ) =>
    hasAccessByPermissionKeys(permissionKeys) ||
    hasAccessByRole(allowedRoles, false);

  /**
   * Function to check if user has access to given path
   * @param path - path of the page to which access is being checked for
   * @returns - boolean
   */
  const checkForPathAccess: CheckForPathAccessFn = (path: string) => {
    const allowedRoles = PAGE_PATH_TO_ROLE_MAPPING[path] || [];
    const permissionsRequired = PAGE_PATH_TO_PERMISSION_MAPPING[path] || [];
    return hasPageAccess(allowedRoles, permissionsRequired);
  };

  /**
   * function to get the next accessible route: supports both role and permission based re-routing
   * @param incomingFeaturesAccessible - list of features accessible to current user
   * @param incomingUserRoleList - list of roles assigned to current user
   * @returns - string => redirect url based on requested feature/role
   */
  const getNextRoute: GetNextRouteFn = useCallback(
    (incomingUserAcls = userAcls, incomingUserRoleList = userRoleList) => {
      // NB: To find out which of the default permission is currently available
      const pathname = window.location.pathname;

      if (
        !NON_AUTHENTICATED_PATHS.includes(pathname) &&
        !checkForPathAccess(pathname)
      ) {
        return '/no-permission';
      }

      const availableDefaultPermission = Object.keys(
        DEFAULT_PERMISSION_TO_ROUTE_MAP,
      )?.find(item => incomingUserAcls?.includes(item));

      // NB: Need to get next route based on the default user acls available
      if (!isEmpty(availableDefaultPermission)) {
        return DEFAULT_PERMISSION_TO_ROUTE_MAP[availableDefaultPermission];
      }

      // NB: Need to get next route based on role, to support static role management
      const userStaticRole = getValidStaticRoleFromUserRoles(
        Object.keys(ROLE_TO_ROUTE_MAP),
        incomingUserRoleList,
      );

      return (
        (userStaticRole && ROLE_TO_ROUTE_MAP[userStaticRole]) ||
        '/no-permission'
      );
    },
    [userAcls],
  );

  return {
    hasAccessByRole,
    getUserPermissions,
    hasPageAccess,
    hasAccessByPermissionKeys,
    checkForPathAccess,
    getNextRoute,
  };
};
