import { ListAdminPortalUserResp } from '@src/service/apis/generatedv3/admin-portal-user-controller';
import create from 'zustand';

interface User {
  user: ListAdminPortalUserResp;
  userRoleList: string[];
  featuresAccessible: string[];
  userAcls: string[];
  setUserAcls: (userAcls: string[]) => void;
  setUserRoleList: (userRoleList: string[]) => void;
  setAccessibleFeatures: (featuresAccessible: string[]) => void;
  setUserData: (user: ListAdminPortalUserResp) => void;
  clearUserData: () => void;
}

const useUserStore = create<User>(set => ({
  user: null,
  userRoleList: null,
  featuresAccessible: null,
  userAcls: null,
  setUserAcls: (userAcls: string[]) => set({ userAcls }),
  setUserRoleList: (userRoleList: string[]) => set({ userRoleList }),
  setAccessibleFeatures: (featuresAccessible: string[]) =>
    set({ featuresAccessible }),
  setUserData: user => set({ user }),
  clearUserData: () => set({ user: null }),
}));

export default useUserStore;
