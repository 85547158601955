const globalConfig = {
  DEFAULT_TOAST_DURATION: 3000,
  DEFAULT_ANCHOR_VERTICAL: 'bottom',
  DEFAULT_ANCHOR_HORIZONTAL: 'left',
  FIREBASE_MIN_FETCH_INTERVAL_IN_MILLISECONDS: 300000, // 5 minutes
  FIREBASE_MIN_FETCH_INTERVAL_IN_MILLISECONDS_PROD: 3600000, // 1 hour (3600000 ms)
  FETCH_TIMEOUT_MILLIS: 300000,
  FETCH_TIMEOUT_MILLIS_PROD: 3600000,
};

export default globalConfig;
