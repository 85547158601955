import {
  Banner,
  PaginationResponseBanner,
} from '@src/service/apis/generatedv3/banner-controller';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

export interface SelectedItemInfo extends Banner {
  id?: number;
  name?: string;
  visible?: boolean;
  afterBannerId?: number | null;
}

export enum BannerVisibilityTypes {
  SHOW_BANNER = 'SHOW_BANNER',
  HIDE_BANNER = 'HIDE_BANNER',
}

export enum ModalTypes {
  TOGGLE_VISIBILITY = 'TOGGLE_VISIBILITY',
  REORDER = 'REORDER',
  DELETE = 'DELETE',
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export interface ConfirmationModalStateProps {
  type: keyof typeof ModalTypes | null;
  selectedItemInfo?: SelectedItemInfo | null;
}

export interface DeleteConfirmationModal {
  handleDelete: () => void;
  handleClose: () => void;
  bannerName: string;
  isOpen: boolean;
}

export interface BannerListingTableProps {
  isLoading: boolean;
  data: PaginationResponseBanner;
  searchInput: string;
  pageSize: number;
  pageNumber: number;
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: () => void;
  handleChangeRowsPerPage: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleChangePage: (newPage: number) => void;
  handleDeleteBanner: (id: number, name: string) => void;
  handleCheckboxClick: (bannerDetails: Banner) => void;
  handleSort: (props: BannerSortFunctionProps) => void;
}

export interface BannerSortFunctionProps {
  oldIndex: number;
  newIndex: number;
}

export interface FilterSectionProps {
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
  handleSearch: () => void;
}

export interface BannerListingActionProps {
  bannerDetails: Banner;
  handleDeleteBanner: (id: number, name: string) => void;
}

export enum BannerPageType {
  ADD = 'add',
  EDIT = 'edit',
  DETAILS = 'details',
}

export enum BANNER_DETAIL_FIELDS {
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
  UPDATED_BY = 'updatedBy',
  UPDATED_AT = 'updatedAt',
}
