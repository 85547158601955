import { INDONESIAN_COUNTRY_CODE } from '@src/constants/common';
import { KycUserKycStep } from '@src/service/apis/generated/kyc-user-controller';
import { ManualKycOnboardingRequestKycChannel } from '@src/service/apis/generatedv3/manual-onboarding-kyc-controller';

export enum Kyc_Details_Fields {
  IS_FOREIGN_COUNTRY = 'isForeignCountry',
  IS_FOREIGN_USER = 'isForeignUser',
  PASSPORT_NUMBER = 'passportNumber',
  NAME = 'name',
  PLACE_OF_BIRTH = 'placeOfBirth',
  DATE_OF_BIRTH = 'dateOfBirth',
  COUNTRY_OF_RESIDENCY = 'countryOfResidency',
  STREET_ADDRESS = 'streetAddress',
  CITY_OF_RESIDENCY = 'cityOfResidency',
  FOREIGN_CITY = 'foreignCity',
  POSTAL_CODE = 'postalCode',
  RELIGION = 'religion',
  MARRIAGE_STATUS = 'marriageStatus',
  EMPLOYMENT = 'employment',
  EMPLOYMENT_STATUS = 'employmentStatus',
  NAME_OF_EMPLOYER = 'nameOfEmployer',
  EMPLOYER_ADDRESS = 'employerAddress',
  EMAIL_ADDRESS = 'emailAddress',
  JOB_TITLE = 'jobTitle',
  EXPIRATION = 'expiration',
  CITIZENSHIP = 'citizenship',
  FUNDING_SOURCE = 'fundingSource',
  ACCOUNT_FUNDING_SOURCE = 'accountFundingSource',
  LIQUID_ASSETS = 'liquidAssets',
  ANNUAL_INCOME = 'annualIncome',
  PASSPORT = 'passport',
  SELFIE = 'selfie',
  KITAP = 'kitap',
  PROVINCE = 'province',
  DISTRICT = 'district',
  SUB_DISTRICT = 'subDistrict',
  CITY = 'cityResidence',
  DOMICILE_ADDRESS = 'domicileAddress',
  DOMICILE_STREET_ADDRESS = 'domicileStreetAddress',
  DOMICILE_PROVINCE = 'domicileProvince',
  DOMICILE_CITY = 'domicileCityResidence',
  DOMICILE_DISTRICT = 'domicileDistrict',
  DOMICILE_SUB_DISTRICT = 'domicileSubDistrict',
  DOMICILE_POSTAL_CODE = 'domicilePostalCode',
  NPWP = 'npwp',
  NPWP_NUMBER = 'npwpNumber',
  EXPIRATION_DATE = 'expirationDate',
  INVESTMENT_OBJECTIVE = 'investmentObjective',
  NIK = 'nik',
  KTP = 'ktp',
  ADDRESS_KITAS = 'addressKitas',
  EXPIRY_KITAS = 'expiryKitas',
  FOREIGN_NPWP_TYPE = 'foreignNpwpType',
  FOREIGN_NPWP_NUMBER = 'foreignNpwpNumber',
  FOREIGN_INVESTMENT_OBJECTIVE = 'foreignInvestmentObject',
  USER_CHANNEL = 'userChannel',
  IS_TERM_PASSED = 'isTermPassed',
  PASSPORT_PHOTO = 'passportPhoto',
  SELFIE_PHOTO = 'selfiePhoto',
  KITAP_PHOTO = 'kitapPhoto',
  KTP_PHOTO = 'ktpPhoto',
  SELFIE_PHOTO_KTP = 'selfiePhotoKtp',
}

export enum User_Form_Fields {
  IS_EXISTING_USER = 'isExistingUser',
  PHONE_NUMBER = 'phoneNumber',
  NANO_TAG = 'nanotag',
  IS_FOREIGN_USER = 'isForeignUser',
}

export enum ERROR_MESSAGE_MKYC {
  REQUIRED = 'Field is required',
  TEXT_LENGTH_3 = 'Minimum Length should be 3',
  TEXT_LENGTH_20_MAX = 'Exceed maximum of 20 characters',
  NIK_LENGTH = 'Allowed number of NIK must in 16 character',
  NANOTAG_PATH = 'Nanotag must not contain special characters',
  PASSPORT_NUMBER_LENGTH = 'Allowed number of characters range from 6 - 9',
  PASSPORT_NUMBER_PATH = 'field should contain only alphanumeric characters',
  TEXT_ERROR_MESSAGE = "Field should contain only alphanumeric characters, space or following symbols -> , . ' - ",
  DATE_OF_BIRTH_18YEARS = 'Age should be >= 18 years',
  DATE_OF_BIRTH_FUTURE = 'Future dates are not allowed',
  TEST_REGEX_NUMBER = 'Field should contain only numeric 0 - 9',
  NPWP_LENGTH = 'Allowed number of NIK must in 15 character',
  EMAIL = 'Please enter a valid email address',
  INVALID_DATE_TYPE = 'Invalid Date Type or Date Format',
  TNC = 'Please recheck terms and conditions',
  ADDRESS = 'Enter a street address with at least three letters',
  POSTAL_CODE_LENGTH = 'Allowed number of postal code must be in 5 characters',
}

export interface TermItem {
  id: string;
  name: string;
  optional: boolean;
  wording: string;
}

export interface ManualKycTandCInterface {
  terms: TermItem[];
  description: string;
}
export interface ManualKYCUploadImageType {
  imageWidthMinimum: number;
  imageWidthMaximum: number;
  imageHeightMinimum: number;
  imageHeightMaximum: number;
  imageMinFileSize: number;
  imageMaxFileSize: number;
}

interface LegalDocItem {
  documentId: string;
  documentURL: {
    en: string;
    id: string;
  };
  type: string;
}
export interface ILegalDoc {
  legal_tnc: LegalDocItem;
  legal_privacy_policy: LegalDocItem;
}

export const RELIGION_OPTIONS = [
  'Islam',
  'Kristen',
  'Katholik',
  'Budha',
  'Hindu',
  'Konghucu',
];

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.[999Z]';

export enum DOMICILE_TYPE {
  SAME_WTIH_KTP = 'SAME_WTIH_KTP',
  DIFFERENT_WITH_KTP = 'DIFFERENT_WITH_KTP',
}

export const DOMICILE_ADDRESS = [
  { key: DOMICILE_TYPE.SAME_WTIH_KTP, value: 'Same with KTP data' },
  { key: DOMICILE_TYPE.DIFFERENT_WITH_KTP, value: 'Different with KTP data' },
];

export enum USER_NPWP_TYPE {
  SAME_WTIH_NIK = 'SAME_WITH_KTP',
  DIFFERENT_WITH_NIK = 'DIFFERENT_WITH_KTP',
  DONT_HAVE_NPWP = 'DONT_HAVE_NPWP',
  HAVE_NPWP_FOREIGN = 'HAVE_NPWP_FOREIGN',
  HAVE_TAX_ID = 'HAVE_TAX_ID',
}

export const NPWP_OPTIONS = [
  { key: USER_NPWP_TYPE.SAME_WTIH_NIK, value: 'Same With KTP' },
  { key: USER_NPWP_TYPE.DIFFERENT_WITH_NIK, value: 'Different With KTP' },
  { key: USER_NPWP_TYPE.DONT_HAVE_NPWP, value: "Doesn't have NPWP" },
];

export const FOREIGN_NPWP_TYPE = [
  {
    value: 'I Have NPWP',
    key: USER_NPWP_TYPE.HAVE_NPWP_FOREIGN,
  },

  {
    value: 'I don’t have NPWP but I have tax ID from other country',
    key: USER_NPWP_TYPE.HAVE_TAX_ID,
  },

  {
    value: 'I don’t have NPWP and don’t have tax ID from other country',
    key: USER_NPWP_TYPE.DONT_HAVE_NPWP,
  },
];

export const FOREIGN_NPWP_TYPE_CHOOSE = {
  'I Have NPWP': 0,
  'I don’t have NPWP but I have tax ID from other country': 1,
  'I don’t have NPWP and don’t have tax ID from other country': 2,
};

export const NPWP_MAP_PAYLOAD = {
  HAVE_TAX_ID: 'tax id',
  DONT_HAVE_NPWP: 'no NPWP',
};

export const MARRIAGE_STATUS_OPTIONS = [
  'Kawin',
  'Belum kawin',
  'Cerai hidup',
  'Cerai mati',
];

export const EMPLOYMENT_OPTIONS = [
  'Belum Bekerja',
  'Ibu Rumah Tangga',
  'Pelajar / Mahasiswa',
  'Pegawai Negeri Sipil',
  'Tentara Nasional Indonesia (TNI)',
  'Polri',
  'Anggota DPR RI',
  'Anggota MPR RI',
  'Anggota DPRD RI',
  'Anggota DPD',
  'Anggota BPK',
  'Anggota Mahkamah Konstitusi',
  'Anggota Kementerian',
  'Duta Besar',
  'Gubernur / wakil gubernur',
  'Bupati /wakil bupati',
  'Walikota / Wakil Walikota',
  'Karyawan BUMN/BUMD',
  'Karyawan Swasta',
  'Karyawan Honorer',
  'Akuntan',
  'Penata Rias',
  'Penata Busana',
  'Penata Rambut',
  'Perancang Busana',
  'Seniman',
  'Notaris',
  'Pengacara',
  'Wartawan',
  'Wiraswasta',
  'Pemuka Agama',
  'Pengajar/Dosen',
  'Lain-lain',
];

export const EMPLOYMENT_STATUS_OPTIONS = [
  'STUDYING',
  'NOT EMPLOYED',
  'EMPLOYED',
  'RETIRED',
];
export const EMPLOYMENT_STATUS_OPTIONS_PAYLOAD = {
  STUDYING: 'STUDYING',
  'NOT EMPLOYED': 'NOT_EMPLOYED',
  EMPLOYED: 'EMPLOYED',
  RETIRED: 'RETIRED',
};

export enum EMPLOYMENT_STATUS_TYPES {
  EMPLOYED = 'EMPLOYED',
}

export enum COUNTRY {
  INDONESIA = 'Indonesia',
}

export const FUNDING_SOURCE_OPTIONS = [
  'SAVINGS',
  'EMPLOYMENT INCOME',
  'BUSINESS INCOME',
  'INVESTMENTS',
  'FAMILY',
  'INHERITANCE',
];

export const FUNDING_SOURCE_PAYLOAD = {
  SAVINGS: 'SAVINGS',
  INVESTMENTS: 'INVESTMENTS',
  FAMILY: 'FAMILY',
  INHERITANCE: 'INHERITANCE',
  'EMPLOYMENT INCOME': 'EMPLOYMENT_INCOME',
  'BUSINESS INCOME': 'BUSINESS_INCOME',
};

export const INVESTMENT_OBJECTIVE = [
  'Gain Profit Margin',
  'Gain More Income',
  'Just want to Invest',
  'Join Investment Speculation',
];

export const USER_CHANNEL_OPTIONS = [
  ManualKycOnboardingRequestKycChannel.IDDB,
  ManualKycOnboardingRequestKycChannel.NANOVEST,
];

export const LIQUID_ASSETS_OPTIONS = [
  {
    value: 'Less than Rp 362 Million',
    key: 'TIER_FIRST',
  },
  {
    value: 'Rp 362 Million - Rp 1.45 Billion',
    key: 'TIER_SECOND',
  },
  {
    value: 'Rp 1.45 Billion - Rp 7.25 Billion',
    key: 'TIER_THIRD',
  },
  {
    value: 'Rp. 7.25 Billion - Rp 14.50 Billion',
    key: 'TIER_FOURTH',
  },
  {
    value: 'More than Rp 14.50 Billion',
    key: 'TIER_FIFTH',
  },
];

export const ANNUAL_INCOME_OPTIONS = [
  {
    value: 'Less than Rp 290 Million',
    key: 'TIER_FIRST',
  },
  {
    value: 'Rp 290 Million - Rp 725 Million',
    key: 'TIER_SECOND',
  },
  {
    value: 'Rp 725 Million - Rp 1.45 Billion',
    key: 'TIER_THIRD',
  },
  {
    value: 'Rp 1.45 Billion - Rp 7.25 Billion',
    key: 'TIER_FOURTH',
  },
  {
    value: 'Rp 7.25 Billion - Rp 14.50 Billion',
    key: 'TIER_FIFTH',
  },
  {
    value: 'More than Rp 14.50 Billion',
    key: 'TIER_SIXTH',
  },
];

export const FILE_FORMATS = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/svg',
  'image/bmp',
  'image/pdf',
];

export const FILE_LABELS = {
  passportPhoto: 'Passport photo',
  selfiePhoto: 'Selfie photo',
  selfiePhotoKtp: 'Selfie photo',
  kitapPhoto: 'Kitap/Kitas photo',
  ktpPhoto: 'Ktp photo',
};

export const labelFieldReligionMarriage = [
  { id: 1, name: 'Religion' },
  { id: 2, name: 'Marriage Status' },
];

export const manualKycUploadDefaultSize = {
  imageWidthMinimum: 256,
  imageHeightMinimum: 256,
  imageWidthMaximum: 4096,
  imageHeightMaximum: 4096,
  imageMinFileSize: 500000,
  imageMaxFileSize: 2000000,
};

export const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9]*$/;

export const NUMERIC_REGEX = /^\d*$/;

export const TOTAL_NIK_NUMBER = 16;

export const TOTAL_NPWP_NUMBER = 15;

export const TEXT_REGEX = /^[A-Za-z0-9\s-.,\']+$/;

export const TEST_REGEX_ERROR_MESSAGE =
  "Field should contain only alphanumeric characters, space or following symbols -> , . ' - ";

export const TEST_REGEX__NUMBER_ERROR_MESSAGE =
  'Field should contain only numeric 0 - 9';

export const EMAIL_ADDRESS_REGEX =
  /^(?!.*\.\.)(?!.*\._)(?!.*\._\.)(?!.*\._\.)[a-zA-Z\d]+(?:[._][a-zA-Z\d]+)*@[^-][a-zA-Z\d-.]+(?:\.[a-zA-Z]+)+$/;

export const EMAIL_ADDRESS_REGEX_LOW_ENV =
  /^(?!.*\.\.)(?!.*\._)(?!.*\._\.)(?!.*\._\.)[a-zA-Z\d+]+(?:[._][a-zA-Z\d+]+)*@[^-][a-zA-Z\d-.]+(?:\.[a-zA-Z]+)+$/;

export const INDONESIAN_PHONE_NUMBER_REGEX = /^8[\d]{7,12}$/;

export const NANOTAG_REGEX = /^\w+$/;

export const ADDRESS_REGEX = /^(.*[a-zA-Z]){3}/;

export const DEFAULT_FORM_ERROR = ' ';

export const mapTermIdToField = {
  '6': Kyc_Details_Fields.ACCOUNT_FUNDING_SOURCE,
  '7': Kyc_Details_Fields.ANNUAL_INCOME,
  '8': Kyc_Details_Fields.LIQUID_ASSETS,
};

export const AUTOCOMPLETE_LIST_LIMIT = 20;

export const TERMS_AND_CONDITIONS_DEFAULT_VALUE = {
  terms: [
    {
      id: '1',
      name: 'Affiliation',
      optional: false,
      wording:
        "I'm not affiliated with or employed by a stock exchange, securities company, regulatory body, member firm of an exchange, FINRA or a municipal securities broker dealer.",
    },
    {
      id: '2',
      name: 'Shareholder',
      optional: false,
      wording:
        "I'm not an officer or >10% shareholder of a pblicly traded company subject to the US Securities Exchange Act 1934",
    },
    {
      id: '3',
      name: 'PEP',
      optional: false,
      wording:
        'Neither I nor any of my immediate family members are currently or formerly Politically Exposed Persons or Public Officials',
    },
    {
      id: '4',
      name: 'US citizen',
      optional: false,
      wording:
        'I certify that I am not a US citizen, US resident alien or other US person for US tax purposes, and I am submitting the applicable Form W8 with this form to certify my foreign status and, if applicable, claim tax treaty benefits.',
    },
    {
      id: '5',
      name: 'Legal',
      optional: false,
      wording:
        'I have read, understood, and agree to be bound by Alpaca Securities LLC and Nanovest account terms, and all other terms, disclosures and disclaimers applicable to me, as referenced in the Application Agreement and Customer Agreement',
    },
    {
      id: '6',
      name: 'Funding Source',
      optional: true,
      wording:
        'I am using my savings as my primary account funding source (uncheck to change your account funding source)',
    },
    {
      id: '7',
      name: 'Annual Income',
      optional: true,
      wording:
        'I have an annual income up to Rp 290 Million (uncheck to change your annual income level)',
    },
    {
      id: '8',
      name: 'Liquid Assets',
      optional: true,
      wording:
        'I have liquid assets up to Rp 362 Million (uncheck to change your liquid assets level)',
    },
    {
      id: '9',
      name: 'Solicitation',
      optional: false,
      wording:
        'I understand I am signing this agreement electronically and that my electronic signature is the legal equivalent of my manual signature on this Agreement',
    },
  ],
  description:
    "Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and belief it is true, correct, and complete.\r\n\r\nI further certify under penalties of perjury that:\r\n• I am the individual that is the beneficial owner (or am authorized to sign for the individual that is the beneficial owner) of all the income to which this form relates or am using this form to document myself for chapter 4 purposes,\r\n• The person named on line 1 of this form is not a U.S. person,\r\n• The income to which this form relates is:\r\n(a) not effectively connected with the conduct of a trade or business in the United States,\r\n(b) effectively connected but is not subject to tax under an applicable income tax treaty, or\r\n(c) the partner's share of a partnership's effectively connected income,\r\n• The person named on line 1 of this form is a resident of the treaty country listed on line 9 of the form (if any) within the meaning of the income tax treaty between the United States and that country, and\r\n• For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in the instructions.\r\n\r\nFurthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or custody of the income of which I am the beneficial owner or any withholding agent that can disburse or make payments of the income of which I am the beneficial owner. I agree that I will submit a new form within 30 days if any certification made on this form becomes incorrect.",
};

export const LEGAL_DOCS_DEFAULT_VALUE: ILegalDoc = {
  legal_tnc: {
    documentId: 'doc-1',
    documentURL: {
      en: 'https://storage.googleapis.com/nanovest-prod/legal-documents/Project%20Nano%20-%20TC%20Nanovest_ENG_01%20Nov%202023_Clean.pdf',
      id: 'https://storage.googleapis.com/nanovest-prod/legal-documents/Project%20Nano%20-%20TC%20Nanovest_IND_01%20Nov%202023_CLean.pdf',
    },
    type: 'PDF',
  },
  legal_privacy_policy: {
    documentId: 'doc-2',
    documentURL: {
      en: 'https://storage.googleapis.com/nanovest-qa/legal-documents/Project%20Nano%20-%20TC%20Nanovest%20Privacy%20Policy%202.0%20Clean_14Oct21%20-%20English.pdf',
      id: 'https://storage.googleapis.com/nanovest-qa/legal-documents/Project%20Nano%20-%20TC%20Nanovest%20Privacy%20Policy%202.0%20Clean_14Oct21_id.pdf',
    },
    type: 'PDF',
  },
};

export const INITIAL_USER_FORM_DATA = {
  isExistingUser: true,
  phoneNumber: '',
  nanotag: '',
  userId: null,
  countryCode: INDONESIAN_COUNTRY_CODE,
};

export const INITIAL_FORM_VALUES = {
  isForeignCountry: true,
  isForeignUser: true,
  passportNumber: '',
  name: '',
  placeOfBirth: '',
  dateOfBirth: '',
  countryOfResidency: undefined,
  streetAddress: '',
  cityOfResidency: undefined,
  foreignCity: '',
  postalCode: '',
  religion: RELIGION_OPTIONS[0],
  marriageStatus: MARRIAGE_STATUS_OPTIONS[0],
  employment: EMPLOYMENT_OPTIONS[0],
  employmentStatus: EMPLOYMENT_STATUS_OPTIONS[2],
  nameOfEmployer: '',
  employerAddress: '',
  emailAddress: '',
  jobTitle: '',
  expiration: '',
  citizenship: undefined,
  fundingSource: FUNDING_SOURCE_OPTIONS[0],
  accountFundingSource: FUNDING_SOURCE_OPTIONS[0],
  liquidAssets: LIQUID_ASSETS_OPTIONS[0].key,
  annualIncome: ANNUAL_INCOME_OPTIONS[0].key,
  addressKitas: '',
  expiryKitas: '',
  foreignNpwpType: '',
  foreignNpwpNumber: '',
  foreignInvestmentObject: INVESTMENT_OBJECTIVE[0],
  userChannel: '',
  // indonesian
  nik: '',
  province: '',
  cityResidence: '',
  district: '',
  subDistrict: '',
  domicileAddress: '',
  domicileStreetAddress: '',
  domicileProvince: '',
  domicileCityResidence: '',
  domicileDistrict: '',
  domicileSubDistrict: '',
  domicilePostalCode: '',
  npwp: '',
  npwpNumber: '',
  investmentObjective: INVESTMENT_OBJECTIVE[0],
  isTermPassed: false,
  ktpPhoto: undefined,
  selfiePhoto: undefined,
  passportPhoto: undefined,
  kitapPhoto: undefined,
  selfiePhotoKtp: undefined,
};

export const MAIN_PAGE_NAVLINK = [
  {
    linkUrl: '/manual-kyc',
    linkValue: 'Manual KYC',
  },
];

export const EXCLUDED_FORCE_CONTINUE_KYC_STEP: (keyof typeof KycUserKycStep)[] =
  [
    KycUserKycStep.LIVENESS_CHECKED,
    KycUserKycStep.EMPLOYMENT_FILLED,
    KycUserKycStep.BASIC_FINANCIAL_FILLED,
    KycUserKycStep.TERMS_GROUND,
  ];
