import create from 'zustand';
import { FirebaseConfig } from '@src/hooks/useFirebaseRemoteConfig/types';

interface MainStore {
  FBRC: FirebaseConfig;
  setFBRC: (config: FirebaseConfig) => void;
  isFBRCLoading: boolean;
  setFBRCLoader: (isLoading: boolean) => void;
  isNanoCardEnabled: boolean | null;
  setIsNanoCardEnabled: (value: boolean) => void;
  isWithdrawalManagementEnabled: boolean;
  setIsWithdrawalManagementEnabled: (value: boolean) => void;
}

const useMainStore = create<MainStore>(set => ({
  FBRC: null,
  setFBRC: (config: FirebaseConfig) => set({ FBRC: config }),
  isFBRCLoading: true,
  setFBRCLoader: (isLoading: boolean) => set({ isFBRCLoading: isLoading }),
  isNanoCardEnabled: null,
  setIsNanoCardEnabled: (value: boolean) => set({ isNanoCardEnabled: value }),
  isWithdrawalManagementEnabled: true,
  setIsWithdrawalManagementEnabled: (value: boolean) =>
    set({ isWithdrawalManagementEnabled: value }),
}));

export default useMainStore;
